import React from 'react'

export default function Sun() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.06019 7.75761L2.66622 4.36364L4.36378 2.6672L7.75775 6.06117L6.06019 7.75761ZM21.3338 4.36364L19.6373 2.6672L16.2434 6.06117L17.9398 7.75761L21.3338 4.36364ZM13.2003 0H10.8008V4.80023H13.2003V0ZM4.80023 10.7997H0V13.1992H4.80023V10.7997ZM16.2433 17.9398L19.6372 21.3338L21.3337 19.6373L17.9397 16.2434L16.2433 17.9398ZM19.1998 10.7997V13.1992H24V10.7997H19.1998ZM12 6.00058C8.69156 6.00058 6.00058 8.69263 6.00058 12C6.00058 15.3084 8.69263 17.9994 12 17.9994C15.3084 17.9994 17.9994 15.3074 17.9994 12C17.9994 8.69156 15.3085 6.00058 12 6.00058ZM2.66737 19.6375L4.36381 21.334L7.75778 17.94L6.06022 16.2435L2.66737 19.6375ZM10.7997 24H13.1992V19.1998H10.7997V24Z"
        fill="url(#paint0_linear_4909_41732)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4909_41732"
          x1="24"
          y1="-3.54545"
          x2="1.92063"
          y2="27.1941"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FBFF26" />
          <stop offset="1" stopColor="#E78511" />
        </linearGradient>
      </defs>
    </svg>
  )
}
