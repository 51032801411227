import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { getProductTypeConfig, objectPath } from 'helpers'
import DesignerCanvas from './designer-canvas'
import DesignerNav from './designer-nav'
import classes from './designer.module.scss'

interface Props {
  order: any
  selectedItem: any
  reRender: any
}

export default function Designer({ order, selectedItem, reRender }: Props) {
  const sliderRef: any = useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 1023 })
  const [Tabs, setTabs] = useState<any>([])
  const { product = {}, productItems = [], additionalOptions = {} } = order
  const customMessage = objectPath(order, ['customMessage'], {})
  const [config] = useState<any>(
    getProductTypeConfig(product, additionalOptions).config,
  )
  const { views, tabs, previewAllTabs } = config
  const flipX =
    product.name.indexOf('Fielding Glove') > -1 && order.throwingHand === 'LHT'

  const [selectedTab, setSelectedTab] = useState<any>({})
  const [previousTab, setPreviousTab] = useState<any>({})

  useEffect(() => {
    if (isMobile) {
      setTabs(tabs)
      setSelectedTab(tabs[0])
    } else {
      setTabs(previewAllTabs)
      setSelectedTab(previewAllTabs[0])
    }
  }, [])

  useEffect(() => {
    if (selectedItem && selectedItem.itemId) {
      const view = views.find((view: any) => {
        return view.productAssets.assets.some(
          (asset: any) => asset.itemId === selectedItem.itemId,
        )
      })

      const tab = tabs.find((tab: any) => {
        return tab.views.indexOf(view) > -1
      })

      if (tab && tab.id) {
        if (isMobile) {
          sliderRef.current.scrollTo(
            sliderRef.current.clientWidth * (tab.id - 1),
            0,
          )
        } else {
          selectTab(tab)
        }
      }
    }
  }, [selectedItem])

  const selectTab = (tab: any) => {
    //to prevent tab selection while animating
    if (previousTab.id) {
      return
    }
    if (selectedTab.id > -1) {
      setPreviousTab(selectedTab)
    }

    setSelectedTab(tab)

    setTimeout(() => {
      setPreviousTab({})
    }, 350)
  }

  const onScroll = (e: any) => {
    const scrollLeft = e.target.scrollLeft
    const scrollWidth = e.target.scrollWidth
    const slideIndex = Math.round((scrollLeft / scrollWidth) * tabs.length)

    setSelectedTab(tabs[slideIndex])
  }

  const renderDesignerCanvas = (tab: any) => {
    return tab.views.map((view: any, index: number) => {
      return (
        view.productAssets &&
        view.productAssets.assets && (
          <DesignerCanvas
            key={view.id}
            viewId={view.id}
            viewType={view.name}
            productType={product.name}
            additionalOptions={additionalOptions}
            itemList={productItems}
            reRender={reRender}
            reset={view.reset}
            customMessage={
              view.id === 'leftCuff'
                ? customMessage.left
                : view.id === 'rightCuff'
                ? customMessage.right
                : {}
            }
            styleProps={tab.styleProps && tab.styleProps[index]}
            styleName={view.styleName || ''}
            prefix={tab.prefix || ''}
            isAdmin={false}
            isInteractive={true}
            flipX={flipX}
            onItemSelect={() => {}}
            onViewUpdate={() => {}}
            onUpdate={() => {}}
          />
        )
      )
    })
  }

  return (
    <div className={classes.container}>
      {Tabs.length > 0 &&
        (isMobile ? (
          <div className={classes.indexSlider}>
            {tabs.map((tab: any) => {
              return (
                <div
                  key={tab.id}
                  className={`${classes.slide} ${
                    tab === selectedTab ? classes.active : ''
                  }`}></div>
              )
            })}
          </div>
        ) : (
          <DesignerNav
            list={Tabs}
            selectedTab={selectedTab}
            reRender={reRender}
            onSelect={(index) => selectTab(Tabs[index])}
          />
        ))}
      <div className={classes.slider} ref={sliderRef} onScroll={onScroll}>
        {Tabs.map((tab: any) => {
          return (
            <div
              className={`${classes.slide} ${
                selectedTab.id === tab.id ? classes.active : ''
              } ${previousTab.id === tab.id ? classes.inactive : ''} ${
                previousTab && selectedTab.id > previousTab.id
                  ? classes.left
                  : ''
              } ${
                previousTab && selectedTab.id < previousTab.id
                  ? classes.right
                  : ''
              }`}
              key={tab.id}>
              <div className={classes.innerSlide}>
                {tab.aspectRatio ? (
                  <div
                    className={classes.aspectRatioContainer}
                    style={{
                      aspectRatio: `${tab.aspectRatio.x} / ${tab.aspectRatio.y}`,
                      flexDirection: flipX ? 'row-reverse' : 'row',
                    }}>
                    {renderDesignerCanvas(tab)}
                  </div>
                ) : (
                  renderDesignerCanvas(tab)
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
